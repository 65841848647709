
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background-color: rgb(250, 255, 189);
  background-image: none;
  color: rgb(0, 0, 0);
}


body {
  margin: 0;
/*   font-family: 'Varela Round';
 */  max-width: 100%;
 /* background-color: #000;*/
/*   overflow-x: hidden;
 */  overflow: hidden;
  position: fixed;

  font-family: 'Varela Round';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  

  -moz-osx-font-smoothing: grayscale;
 
}
/* 

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid rgb(240, 0, 0);
} */
/* body::-webkit-scrollbar {
  display: none;
 }
 */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* 
@font-face {
  font-family: 'tangore';
  src: url('./components/fonts/tangore.ttf');
}
 */
/* @font-face {
  font-family: 'amiro';
  src: asset-url('./components/fonts/Arimo.ttf');
  font-weight: normal;
  font-style: normal;
}
 */
/* 
@font-face {
  font-family: 'amadeusb';
  src: url('./components/fonts/amadeusb.ttf');
} */

@keyframes open {
  0%    { transform: translate(0%, -100%);}
  100%  { transform: translate(0%, 0%);   }
}
@keyframes close {
  0%    { transform: translate(0%, 0%);    }
  100%  { transform: translate(0%, -100%); }
}
 
 
@-webkit-keyframes open {
  0%    { transform: translate(0%, -100%);    }
  100%  { transform: translate(0%, 0%); }
} 
@-webkit-keyframes close {
  0%    { transform: translate(0%, 0%);    }
  100%  { transform: translate(0%, -100%); }
} 

/* -- -- -- -- -- -- -- -- --- -- -- -- -- -- - -*/

@keyframes move_right {
  0%    { transform: translate(-50%, 0%); }
  100%  { transform: translate(15%, -15%) rotate(-25deg); }
}
@keyframes move_left {
  0%    { transform: translate(15%, -15%) rotate(-25deg); }
  100%  { transform: translate(-50%, 0%); }
}
 
 
@-webkit-keyframes move_right {
  0%    { transform: translate(-50%, 0%); }
  100%  { transform: translate(15%, -15%) rotate(-25deg); }
} 
@-webkit-keyframes move_left {
  0%    { transform: translate(15%, -15%) rotate(-25deg); }
  100%  { transform: translate(-50%, 0%); }
} 


/* -- -- -- -- -- -- -- -- --- -- -- -- -- -- - -*/


@-webkit-keyframes float {
  0%    { transform: translate(0px, 20px) rotate(-10deg);  }
  50%   { transform: translate(0px, -20px) rotate(10deg); }
  100%  { transform: translate(0px, 20px) rotate(-10deg);  }
}
@keyframes float {
  0%    { transform: translate(0px, 20px) rotate(-10deg);  }
  50%   { transform: translate(0px, -20px) rotate(10deg); }
  100%  { transform: translate(0px, 20px) rotate(-10deg);  }
}

/* -- -- -- -- -- -- -- -- --- -- -- -- -- -- - -*/

 
@-webkit-keyframes spin {
  100%  { transform: rotate(360deg);  }
}
@keyframes spin {
  100%  { transform: rotate(360deg);  }
}

@-webkit-keyframes counterspin {
  100%  { transform: rotate(-360deg);  }
}
@keyframes counterspin {
  100%  { transform: rotate(-360deg);  }
}
 

/* -- -- -- -- -- -- -- -- --- -- -- -- -- -- - -*/


@-webkit-keyframes puls {
  0%    { box-shadow: 0px 0px 10px 10px #FFF; background-color: #FFF; }
  50%   { box-shadow: 0px 0px 0px 0px #FFF; background-color: transparent; }
  100%  { box-shadow: 0px 0px 10px 10px #FFF; background-color: #FFF; }
}
@keyframes puls {
  0%    { box-shadow: 0px 0px 10px 10px #FFF ; background-color: #FFF;}
  50%   { box-shadow: 0px 0px 0px 0px #FFF; background-color: transparent; }
  100%  { box-shadow: 0px 0px 10px 10px #FFF; background-color: #FFF; }
}

/* -- -- -- -- -- -- -- -- --- -- -- -- -- -- - -*/


/* -- -- -- -- -- -- -- -- --- -- -- -- -- -- - -*/


@-webkit-keyframes puls2 {
  0%    { box-shadow: 0px 0px 10px 10px rgba(250, 250, 250, 0.75);  }
  50%   { box-shadow: 0px 0px 0px 0px rgba(250, 250, 250, 0.75);    }
  100%  { box-shadow: 0px 0px 10px 10px rgba(250, 250, 250, 0.75);  }
}
@keyframes puls2 {
  0%    { box-shadow: 0px 0px 10px 10px rgba(250, 250, 250, 0.75) ; }
  50%   { box-shadow: 0px 0px 0px 0px rgba(250, 250, 250, 0.75);    }
  100%  { box-shadow: 0px 0px 10px 10px rgba(250, 250, 250, 0.75);  }
}

/* -- -- -- -- -- -- -- -- --- -- -- -- -- -- - -*/


@-webkit-keyframes twinckle {
  0%   { opacity: 0  }
  40%  { opacity: 0  }
  50%  { opacity: 1  }
  60%  { opacity: 0  }
  100% { opacity: 0  }
}
@keyframes twinckle {
  0%   { opacity: 0  }
  40%  { opacity: 0  }
  50%  { opacity: 1  }
  60%  { opacity: 0  }
  100% { opacity: 0  }
}
/* -- -- -- -- -- -- -- -- --- -- -- -- -- -- - -*/


@keyframes pyrotechnics {
  0%    { transform: translate(0%, 0%); }
  100%  { transform: translate(0%, 20%) }
}

@-webkit-keyframes pyrotechnics {
  0%    { transform: translate(0%, 0%); }
  100%  { transform: translate(0%, 20%) }
}


/* -------------------------------------------------- */



@-webkit-keyframes shake {
  0%  { transform: translate(1px, 0px) rotate(0deg); }
  10% { transform: translate(-17px, 0px) rotate(0deg); }
  20% { transform: translate(-30px, 0px) rotate(0deg); }
  30% { transform: translate(18px, 0px) rotate(0deg); }
  40% { transform: translate(1px, 0px) rotate(0deg); }
  50% { transform: translate(-30px, 0px) rotate(0deg); }
  60% { transform: translate(-13px, 0px) rotate(0deg); }
  70% { transform: translate(30px, 0px) rotate(0deg); }
  80% { transform: translate(-1px, 0px) rotate(0deg); }
  90% { transform: translate(30px, 0px) rotate(0deg); }
  100% { transform: translate(1px, 0px) rotate(0deg); }
} 


@keyframes shake {
  0%  { transform: translate(1px, 0px) rotate(0deg); }
  10% { transform: translate(-17px, 0px) rotate(0deg); }
  20% { transform: translate(-30px, 0px) rotate(0deg); }
  30% { transform: translate(18px, 0px) rotate(0deg); }
  40% { transform: translate(1px, 0px) rotate(0deg); }
  50% { transform: translate(-30px, 0px) rotate(0deg); }
  60% { transform: translate(-13px, 0px) rotate(0deg); }
  70% { transform: translate(30px, 0px) rotate(0deg); }
  80% { transform: translate(-1px, 0px) rotate(0deg); }
  90% { transform: translate(30px, 0px) rotate(0deg); }
  100% { transform: translate(1px, 0px) rotate(0deg); }
}

